
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import ReActivationFilters from "./pending-requests/ReActivationFilters.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Modal } from "bootstrap";
import { useStore } from "vuex";
import RejectModal from "./pending-requests/RejectModal.vue";

export default defineComponent({
  name: "re-activations-table",
  components: {
    ReActivationFilters,
    RejectModal
  },
  props: {
    widgetClasses: String,
    filterData: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      rejectionRequestReasons: {},
      selectedDonorId: null,
      selectedDonorType: "",
      terminationReason: "",
      columns: [],
      entities: [],
      filters: {},
      count: 0,
      pagination: {
        page: 1,
        perPage: 10,
        total: 0,
      },
      loading: false,
      initialized: false,
      selectedAll: false,
    };
  },
  methods: {
    retrieveReasons() {
      this.initialized = true;
      this.loading = true;
      ApiService.get("rejection_reasons_request").then((response) => {
        this.loading = false;
        this.rejectionRequestReasons = response.data.data;
      }).catch((error) => {
        console.error("Error fetching rejection_reasons_request data:", error);
        this.loading = false;
      });
    },
    retrieveData() {
      this.initialized = true;
      this.loading = true;
      ApiService.query("donor_reactivations", {
        params: {
          filters: this.filters,
          perPage: this.pagination.perPage,
          page: this.pagination.page,
        },
      }).then((response) => {
        this.$emit("onRetrieve", response.data);
        this.loading = false;
        this.entities = response.data.data;
        this.columns = response.data.columns; 
        this.pagination.total = response.data.meta.total;
        this.count = response.data.meta.total;
      }).catch((error) => {
        console.error("Error fetching inactivation data:", error);
      });
    },
    getEntityValue(entity, columnName) {
      // Obtiene el valor dinámico para una columna
      return entity[columnName] ?? "N/A";
    },
    getColumnClass(column) {
      return column.sortable ? "sortable-column" : "";
    },
    getCustomColumnClass(columnName) {
      switch (columnName) {
      case "type":
        return "column-type";
      case "status":
        return "column-status";
      default:
        return "";
      }
    },
    getStatusText(status) {
      switch (status) {
        case "pending":
          return "Pendiente";
        case "approved":
          return "Aprovado";
        case "rejected":
          return "Rechazado";
        default:
          return "Desconocido";
      }
    },
    getStatusClass(status) {
      switch (status) {
        case "pending":
          return "warning";
        case "approved":
          return "success";
        case "rejected":
          return "danger";
        default:
          return "dark";
      }
    },
    translateStatus(status) {
    const statusTranslations = {
      pending: "Pendiente",
      approved: "Aprobado",
      rejected: "Rechazado",
    };
    return statusTranslations[status] || "Desconocido"; // Devuelve "Desconocido" si no coincide
  },
    handleValidate(id) {
      console.log("Validando Baja con ID:", id);
    },
    updateFilters(newFilters) {
      this.filters = newFilters;
      this.retrieveData();
    },
    formatDate(date) {
      // Ajustar formato de fecha
      return new Date(date).toLocaleDateString();
    },
    exportData() {
      Swal.fire({
        title: "Exportación en progreso",
        html: "",
        timer: 300000,
        allowOutsideClick: false,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          ApiService.query("export/" , {
            responseType: "blob",
            params: {
              perPage: this.pagination.perPage,
              page: this.pagination.page,
              filters: this.filterData,
              //  filterString: this.filters.string,
            },
          })
            .then((response) => {
              Swal.close();
              const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = "donantes-cidd.xlsx";
              link.click();
            })
            .catch((error) => {
              Swal.fire({
                title: "Ocurrió un error al exportar los datos",
                text: "Compruebe que la cantidad de resultados sea inferior a 12.000 y vuelva a intentarlo.",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Cerrar",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        },
      });
    },
    terminateForm() {
      var modalito = new Modal(document.getElementById("check-modal"));
      modalito.show();
    },
    openCheckModal(donorId) {
      this.selectedDonorId = donorId;
      this.performControlAnd(donorId,"Reactivar Donante", () => {
        this.retrieveData();
        Swal.fire({
          title: "El donante se reactivó con éxito",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        })
      });
    },
    performControlAnd(donor_id, title, callback, failed?) {
        Swal.fire({
          title: title,
          width: 500,
          text: "¿La donante respondió satisfactoriamente las preguntas del cuestionario?",
          icon: "question",
          confirmButtonText: "Sí",
          showConfirmButton: true,
          showDenyButton: true,
          showCancelButton: true,
          denyButtonText: "No",
          cancelButtonText: "Cancelar",
        })
          .then((result) => {
            if (result.isConfirmed) {
              this.$router.push({
              name: "donor-edit",
              params: { id:  this.selectedDonorId },
              query: { reactivate: "true" },
            });
          } else if (result.isDenied || result.isDismissed) {
            if (failed) failed();
          }
        })
          .catch((err) => {
            console.log(err);
          });
      },
  },
  mounted() {
    this.retrieveData();
    this.retrieveReasons();
  },
  setup() {
    const InactivationCheckModal = ref<null | HTMLElement>(null);

    const store = useStore();

    return  {
      InactivationCheckModal
    }

  }
});
